.invoice-body, .invoice-html {
    margin: 0;
}
.invoice-pdf {
    font-size: 34px;
    min-width: 2100px;
    max-width: 2100px;
    min-height: 2850px;
    max-height: 2850px;
    margin: 225px;
    overflow: hidden;
    * {
        font-family: 'Helvetica', sans-serif;
        margin: 0;
        padding: 0;
        line-height: 30px;
    }
    p {
        margin: 0;
        padding: 0;
    }
    table {
        min-width: 2100px;
        max-width: 2100px;
        display: inline-table;
    }
    td {
        padding: 0 16.5px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
    }
    th {
        padding: 8px 16.5px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        font-size: 31.5px;
        line-height: 42.7px;
    }

    .invoice-logo {
        width: 970px;
    }
    .invoice-header {
        border-top: 5px solid black;
        .header-info {
            font-size: 31.5px;
            line-height: 40px;
        }
        .payment-url {
            font-size: 40px;
            line-height: 42px;
        }
    }
    .record-column {
        max-width: 170px;
    }
    .agency-column {
        max-width: 297px;
    }
    .arc-column {
        max-width: 149px;
    }
    .confirmation-column {
        max-width: 306px;
    }
    .name-column {
        max-width: 285px;
    }
    .reconciled-name-column {
        max-width: 284px;
    }
    .checkin-column {
        max-width: 176px;
    }
    .nights-column {
        max-width: 81px;
    }
    .rate-column {
        max-width: 170px;
    }
    .commission-column {
        max-width: 159px;
    }
    .reconciled-commission-column {
        max-width: 120px;
    }
    .reconciled-paid-column {
        max-width: 150px;
    }
    .invoice-total {
        margin: 26px 0;
        text-align: right;
        font-weight: bold;
        font-style:italic;

        p {
            margin: 0 83px;
            display: inline-block;
            font-weight: bold;
            font-style:italic;
        }
    }
    .final-note {
        text-align: center;
        display: table;

        .warning-text {
            vertical-align: bottom;
        }
        div {
            display: table-cell;
            p {
                text-align: left;
                line-height: 40px;
            }
        }
    }

    .text-red {
        color: #f84040;
    }
    .large {
        font-size: 34px;
        line-height: 39px;
        font-weight: bold;
    }
    .collections {
        font-size: 42px;
        font-weight: bold;
        margin-top: 100px;
        margin-bottom: 100px;

        p {
            line-height: 47px;
        }
    }
    .record {
        font-size: 34px;
        line-height: 46px;
        font-weight: bold;
    }
    .wide {
        width: 2100px;
    }
    .left {
        text-align: left;
    }
    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }
    .add-margin-top {
        margin-top: 18px;
    }
    .add-margin-bottom {
        margin-bottom: 18px;
    }
    .half-line {
        line-height: 12px;
    }
}
