.invoice-reconciliation {
    .invoice-reconciliation-header {
        display: flex;
        justify-content: space-between;

        .invoice-id {
            display: inline-block;
        }

        .invoice-completed {
            font-size: 1.25em;
            font-weight: 700;
            margin-left: 1em;
        }

        .invoice-totals {
            display: flex;

            table {
                font-size: 1.1rem;
                th, td {
                    padding: 0.5em;
                    text-align: right;
                    border: solid 1px black;
                }

                tbody {
                    th {
                        font-weight: normal;
                    }

                    tr.total-row td {
                        font-weight: bold;
                    }

                    td.paid-pending {
                        background: #ffffaa;
    ;
                    }
                }
            }

            .table-due {
                // border-right: solid 1px black;
                margin-right: 1em;
            }
        }

    }

    .reconciliation-table-container {
        .table-metrics {
            span {
                font-size: 1.2rem;
            }
        }
    }
    .invoice-reconciliation-table {
        tr.first-unreconciled {
            border-top: 2px solid black;
        }
        td {
            vertical-align: center;
            &.edited {
                input, select {
                    background-color: #ffffaa;
                }
            }
        }
    }

    .reconciliation-term {
        font-weight: bold;
    }
}
