// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Arimo');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Components
@import 'components/invoice-pdf';
@import 'components/navbar-prod';
@import 'components/reconciliation';

// Global
body,
button,
select {
    text-transform: uppercase;
}
